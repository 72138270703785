// Dependencies
import React, { useContext, useState } from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
// Hooks
// ...
// Components
import { AppActionsContext } from "../components/App";
import Layout from "../components/Layout";
import LinkType from "../components/LinkType";
import Img from "../components/Img";
import Lightbox from "../components/Lightbox";
import RichText from "../components/RichText";
import { useToggleHover } from '../components/ToggleCursor';

const Person = ({ entry, setPerson, style }) => {
	
	// Content
	const { title, name, portrait } = entry;

	// UI
	const [setShowCursor] = useContext(AppActionsContext);

	const handlers = {
		onClick: () => setPerson(entry),
		onMouseEnter: () => setShowCursor(true),
		onMouseOver: () => setShowCursor(true),
		onMouseLeave: () => setShowCursor(false)
	}

	return (
		<div className="person" {...handlers} style={style}>
			<div className="">
				<Img {...portrait} />
			</div>
			<h5>{name}</h5>
			<h5>{title}</h5>
		</div>
	)
}

const PersonOverlay = ({ entry, handleClose }) => {

	// UI
	const [handlers] = useToggleHover();

	return (
		<Lightbox handleClose={handleClose}>
			<div className="column stick">
				<Img {...entry.portrait} />
			</div>
			<div className="column">
				<h3>{entry.name}</h3>
				{entry.title && <h3>{entry.title}</h3>}
				{entry.email &&<h3><span>{'> '}</span><a href={`mailto:${entry.email}`} className="link" target="_blank" {...handlers}>{`${entry.email}`}</a></h3>}
				<div className="outerx2">
					<RichText content={entry.biography} />
				</div>
				{entry.project && (
					<div className="p3 has-links has-break-indents">
						<h5>Work</h5>
						<ul>
							{entry.project.map((project) => (
								<li key={project.id} {...handlers}>
									<Link to={`/project/${project.slug}`}>{project.title}</Link>
								</li>
							))}
						</ul>
					</div>
				)}
				{entry.activity && (
					<div className="p3 has-links has-break-indents">
						<h5>Activity</h5>
						<ul>
							{entry.activity.map(({ id, slides, information, type, slug, externalUrl, title }) => (
								<li key={id} {...handlers}>
									<LinkType 
										to={(slides || information) ? `/activity/${type.slug}/${slug}` : null} 
										url={externalUrl}
									>
										{title}
									</LinkType>
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
		</Lightbox>
	)
}

const StudioPage = ({ data }) => {

	// Content
	const { about, credentials, people, clients } = data.allContentfulStudio.nodes[0];

	// UX
	const [activePerson, setPerson] = useState(null);

	// UI
	const handleClose = () => {
		setPerson(null)
	} 

	return (
		<Layout title="Studio" headerTitle={"Studio"}>
			<section className="outerx2 guttersx2">
				<div className="p3">
					<RichText content={about} />
				</div>
				<div className="p3 grid-3 outerx4">
					{credentials.map((entry, index) => (
						<div className="has-break-indents" key={`cred-${index}`}>
							<RichText content={entry.copy} />	
						</div>
					))}
				</div>
				<div className="outerx4">
					<h3 className="outerx4">People</h3>
					<div className="grid-4">
						{people.map((entry, index) => (
							<Person 
								entry={entry}
								setPerson={setPerson}
								key={entry.name} 
							/>
						))}
					</div>
				</div>
				<div className="p3 outerx4 studio-clients has-break-indents">
					<h3 className="outerx2">Clients</h3>
					<RichText content={clients} />
				</div>
			</section>

			{activePerson && (
				<PersonOverlay entry={activePerson} handleClose={handleClose} />
			)}
  	</Layout>
	)
}

StudioPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default StudioPage

export const StudioPagePageQuery = graphql`
  query StudioPageQuery {
    allContentfulStudio {
			nodes {
				about {
					raw
				}
				credentials {
					copy {
						raw
					}
				}
				people {
					name
					title
					email: eMail
					portrait {
						fluid(maxWidth: 600) {
							...GatsbyContentfulFluid_noBase64
						}
					}
					biography {
						raw
					}
					project {
						id
						title
						slug
					}
					activity {
						id
						title
						slug
						type {
							slug
						}
						slides {
							id
						}
						information {
							raw
						}
						externalUrl
					}
				}
				clients {
					raw
				}
			}
		}
  }
`
